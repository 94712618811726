import React from "react"
import { useSelector } from "react-redux"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ButtonArrow from '../components/buttonArrow'

import trads from '../intl/trads.json'

const NotFoundPage = () => {
  const lang = useSelector(state => state.lang)
  const browser = typeof window !== 'undefined' && window

  return (
    <>
      {browser && 
        <Layout
          initLocale="fr"
          frUrl="/" enUrl="/en"
          headerType="bg-white"
          is404={true}>

          <Seo
            page={{
              title: `404 - ${trads[lang].notFound.title}`,
            }}
          />
          <div className="w-full py-12 flex items-center justify-center">
            <div className="text-center">
              <span className="title title--turquoise title-slice">404</span>
              <h1 className="title title-page mb-8">{trads[lang].notFound.title}</h1>
              <p className="paragraph mb-10">{trads[lang].notFound.desc}</p>

              <ButtonArrow
                text={trads[lang].notFound.link}
                link="/"
                color="night" />
            </div>
          </div>
        </Layout>
      }
    </>
  )
}

export default NotFoundPage
